<template>
  <div>
    <topHeader :haveBanner="false" />
    <section>
      <div class="authentication">
        <div class="container">
          <p class="authentication__caption text-center">Reset Password.</p>
          <div class="__spacet2p"></div>
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-2"></div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-8">
              <div class="__formwidth">
                <form @submit.prevent="handleResetPassword">
                  <div class="form-group">
                    <label for="" class="form__authformlabel">Password</label>
                    <input
                      :type="showPassword ? 'text':'password'"
                      class="form-control form__authformedit"
                      placeholder="****************"
                      v-model.trim="password"
                      :class="{ 'has-error': v$.password.$errors.length }"
                    />
                    <span class="__showpassword" style="color: gray; fontSize: 14px" @click.prevent="showPassword = !showPassword">
                      <i class="fa fa-eye-slash" v-if="!showPassword" aria-hidden="true"></i>
                      <i class="fa fa-eye" v-if="showPassword" aria-hidden="true"></i>
                    </span>
                    <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                      <div class="error-msg invalid-feedback">{{ error.$message }}</div>
                    </div>
                  </div>

									<div class="form-group">
                    <label for="" class="form__authformlabel"
                      >Confirm Password</label
                    >
                    <input
                      :type="showConfirmPassword ? 'text':'password'"
                      class="form-control form__authformedit"
                      placeholder="****************"
                      name="password"
                      v-model="password_confirmation"
                      :class="{ 'has-error': v$.password_confirmation.$errors.length }"
                    />
                    <span class="__showpassword" style="color: gray; fontSize: 14px" @click.prevent="showConfirmPassword = !showConfirmPassword">
                      <i class="fa fa-eye-slash" v-if="!showConfirmPassword" aria-hidden="true"></i>
                      <i class="fa fa-eye" v-if="showConfirmPassword" aria-hidden="true"></i>
                    </span>
                    <div class="input-errors" v-for="error of v$.password_confirmation.$errors" :key="error.$uid">
                      <div class="error-msg invalid-feedback">{{ error.$message }}</div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-block form__authformbutton"
                    style="display: flex; justifyContent: center; alignItem: center"
                  >
                    Send <Loader :shouldShow="shouldShowLoader" />
                  </button>
                </form>

                <p class="authentication__log text-center">
                  Still remember your password?
                  <router-link to="/login" class="__loglink">
                    Login into your account
                  </router-link>
                </p>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-2"></div>
          </div>
        </div>
        <div class="__spacet7p"></div>
      </div>
    </section>
    <topFooter />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import useVuelidate from '@vuelidate/core';
import { required, helpers, sameAs, minLength } from '@vuelidate/validators';

import topHeader from "../components/topHeader.vue";
import topFooter from "../components/topFooter";
import Loader from "../components/loader";
import { errorHandler } from "../../utils/helpers";

export default {
  name: "ResetPassword",
  components: {
    topHeader,
    Loader,
    topFooter,
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      shouldShowLoader: false,
			showPassword: false,
      showConfirmPassword: false
    };
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage('Password is required', required),
        minLength: helpers.withMessage('Password must have a minimum length of 6 digits', minLength(6)),
      },
			password_confirmation: {
        required: helpers.withMessage('Confirm password is required', required),
        minLength: helpers.withMessage('Password must have a minimum length of 6 digits', minLength(6)),
        sameAsPassword: helpers.withMessage('Confirm password must be the same as Password', sameAs(this.password))
      }
    }
  },
  methods: {
    ...mapActions([
      "resetPassword"
    ]),
    async handleResetPassword() {
      this.v$.$touch();
      try {
        if (this.v$.$error) return;

        this.shouldShowLoader = true;
				
        const result = await this.resetPassword({
          password: this.password,
          password_confirmation: this.password_confirmation,
					key: this.$route.query.key
        });

        if (result.errors && result.errors.length > 0) {
          this.shouldShowLoader = false;
          return this.$toast.error(`${result.errors[0]}`);
        }

        this.$toast.success(`${result.message}`);

        this.input = {};
				this.$router.push(`/login`);

      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.shouldShowLoader = false;
      }
    },
  },
};
</script>

<style lang="scss">
</style>